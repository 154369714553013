import { Result } from 'services/types'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useToggle } from 'shared/hooks'

import { maskedDateTime } from 'utilities/date'
import { EventTag } from 'domains/occurrence/components/EventTag/EventTag'

import {
  PaginationV2 as Pagination,
  LoaderV2 as Loader,
  TableV2 as Table,
  ContainerScreen,
  EmptyState,
  Button,
  Input,
  Icon,
} from 'components'

import { joiResolver } from '@hookform/resolvers/joi'
import { FormProvider, useForm } from 'react-hook-form'

import OccurrenceDriver from 'services/occurrence'
import { OccurrenceListResponse } from 'services/occurrence/types'

import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'
import { Filter } from './components/Filters'
import { ReactComponent as FiltersIcon } from 'assets/svg/serviceOrdersFilter.svg'

import styles from './styles.module.scss'
import {
  occurrenceListFiltersSchema,
  OccurrenceListFiltersSchema,
} from 'domains/occurrence/schemas/searchOccurrenceListSchema'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { buildPath, paths } from 'routes'

const columnHelper = createColumnHelper<OccurrenceListResponse>()

const Occurrences = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { getFilters, setFilters } =
    usePersistentFilters<OccurrenceListFiltersSchema>('occurrences')

  const [isError, setIsError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState<Result<OccurrenceListResponse>>({
    data: [],
    totalElements: 0,
  })

  const filterSheetProps = useToggle()

  const form = useForm<OccurrenceListFiltersSchema>({
    resolver: joiResolver(occurrenceListFiltersSchema),
    mode: 'onChange',
    defaultValues: getFilters(),
  })

  const { watch, handleSubmit, register, reset, setValue } = form

  const { number, occurrenceType, account, partition } = watch()

  const fetchOccurrences = useCallback(async () => {
    setIsFetching(true)
    const currentPage = Number(searchParams.get('page')) || 0
    const size = 15

    if (number) {
      reset()
      setValue('number', number)
      setFilters(watch())
    }

    try {
      const occurrences = await OccurrenceDriver.findAllOccurrences({
        size,
        page: currentPage - 1,
        sort: 'createdAt,desc',
        stateNames: [
          'CREATED',
          'AVAILABLE',
          'IN_PROGRESS',
          'TRAVEL_WAITING',
          'TRAVEL_SENT',
          'TRAVEL_VIEWED',
          'TRAVEL_STARTED',
          'TRAVEL_FINISHED',
          'QUEUED',
          'FINISHED',
          'HIDDEN',
        ],
        ...(number && { number }),
        ...(occurrenceType && { occurrenceTypeId: occurrenceType.id }),
        ...(account && { accountId: account.id }),
        ...(partition && { partitionId: partition.id }),
      })
      setData(occurrences)
    } catch {
      setIsError(true)
    } finally {
      setIsFetching(false)
    }
  }, [
    number,
    occurrenceType,
    account,
    reset,
    setValue,
    setFilters,
    watch,
    searchParams,
  ])

  const page = searchParams.get('page')
  const filter = searchParams.get('filter')

  useEffect(() => {
    if (page || filter) {
      fetchOccurrences()
    }
  }, [page, filter])

  const handleView = useCallback(
    (occurrenceId: string): void => {
      navigate(
        buildPath({
          path: paths.occurrence.attendance,
          params: { occurrenceId },
        }),
        {
          state: {
            readonly: true,
            from: 'management-occurrence',
          },
        },
      )
    },
    [history],
  )

  const actions = useMemo(
    () => [
      {
        label: 'Visualizar',
        Icon: 'view',
        handler: (occurrence) => handleView(occurrence.id),
      },
    ],
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor('number', {
        size: 60,
        id: 'number',
        header: 'Número',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row, {
        header: 'Conta',
        cell: (info) => {
          const customer = info.row.original.customer?.name
          const code = info.row.original.account?.code
          return `${code} - ${customer}`
        },
      }),
      columnHelper.accessor('typeName', {
        size: 63,
        header: 'Tipo',
        cell: (info) => {
          return (
            <span className={styles.tagContainer}>
              <EventTag type={info.getValue()} />
            </span>
          )
        },
      }),
      columnHelper.accessor('operator', {
        size: 93,
        header: 'Operador',
        cell: (info) => info.getValue()?.name,
      }),
      columnHelper.accessor('createdAt', {
        size: 93,
        header: 'Abertura',
        cell: (info) => maskedDateTime(info.getValue()),
      }),
      columnHelper.accessor('finishedAt', {
        size: 93,
        header: 'Fechamento',
        cell: (info) => info.getValue() && maskedDateTime(info.getValue()),
      }),
      columnHelper.accessor((row) => row, {
        id: 'action',
        header: '',
        size: 42,
        cell: (info) => {
          const value = info.getValue()

          return (
            <Icon
              width={16}
              height={12}
              name={actions[0].Icon}
              onClick={() => actions[0].handler(value)}
              style={{ cursor: 'pointer' }}
            />
          )
        },
      }),
    ],
    [columnHelper],
  )

  const handleResetPagination = () => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: '0',
    })
  }

  const table = useReactTable({
    columns,
    data: data?.data || [],
    getCoreRowModel: getCoreRowModel(),
  })

  const handleRender = (
    data: unknown[] | undefined,
    isError: boolean,
    isFetching: boolean,
  ) => {
    if (!isFetching && data?.length) {
      return 'view'
    }
    if (isFetching) {
      return 'loading'
    }
    if (isError) {
      return 'error'
    }
    return 'empty'
  }

  return (
    <>
      <FormProvider {...form}>
        <ContainerScreen
          title="Listagem geral"
          clickable={!filterSheetProps?.isVisible}
          renderFilters={
            <form
              onSubmit={(event) => {
                event.preventDefault()
                handleResetPagination()

                reset({
                  number,
                })
                fetchOccurrences()
              }}
            >
              <section className={styles.innerWrapper}>
                <Input
                  label="Número da ocorrência"
                  placeholder="Digite o nº identificador da ocorrência"
                  {...register('number')}
                  autoComplete="off"
                  disabled={filterSheetProps?.isVisible}
                />
                <Button
                  buttonTitle="Pesquisar"
                  type="primary"
                  htmlType="submit"
                  disabled={filterSheetProps.isVisible}
                />
                <Button
                  buttonTitle={
                    Object.values(watch())
                      .slice(2, 7)
                      .some((value) => value)
                      ? 'Editar filtros'
                      : 'Adicionar filtros'
                  }
                  type="tertiary"
                  icon={FiltersIcon}
                  onClick={filterSheetProps?.show}
                />
              </section>
            </form>
          }
        >
          <div className={styles.wrapper}>
            {
              {
                view: (
                  <div className={styles.tableContainer}>
                    <Table.Root>
                      <Table.Header>
                        {table?.getHeaderGroups().map((headerGroup) => (
                          <Table.Row key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                              <Table.ResizableColumn
                                key={header.id}
                                colSpan={header.colSpan}
                                style={{ width: header.column.getSize() }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                              </Table.ResizableColumn>
                            ))}
                          </Table.Row>
                        ))}
                      </Table.Header>
                      <Table.Body className={styles.tbody}>
                        {table?.getRowModel().rows.map((row) => (
                          <Fragment key={row.id}>
                            <Table.Row>
                              {row.getVisibleCells().map((cell) => (
                                <Table.Cell
                                  key={cell.id}
                                  className={styles[cell.column.id]}
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext(),
                                  )}
                                </Table.Cell>
                              ))}
                            </Table.Row>
                          </Fragment>
                        ))}
                      </Table.Body>
                    </Table.Root>
                  </div>
                ),
                empty: <EmptyState type="EmptyDataFromBFF" />,
                error: <EmptyState type="ErrorToLoadInfos" />,
                loading: (
                  <div className={styles.loader}>
                    <Loader />
                  </div>
                ),
              }[handleRender(data?.data, isError, isFetching)]
            }
            <Pagination
              className={styles.pagination}
              totalElements={data?.totalElements}
            />
          </div>
          <Filter
            isVisible={filterSheetProps.isVisible}
            onClose={filterSheetProps.hide}
            onApplyFilters={handleSubmit(() => {
              if (number) {
                form.setValue('number', undefined)
              }

              handleResetPagination()
            })}
          />
        </ContainerScreen>
      </FormProvider>
    </>
  )
}

export default Occurrences
